import React from "react";

function RefundPolicy() {
  return (
    <div className="max-w-4xl mx-auto my-10 px-4">
      <h1 className="my-20 font-poppins text-2xl text-center font-bold">
        Return and Refund Policy
      </h1>
      <p className="text-sm font-poppins mb-4 text-black">
        Last updated: August 06, 2024
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        Thank you for shopping at Homeohopes.
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        If, for any reason, You are not completely satisfied with a purchase We
        invite You to review our policy on refunds and returns.
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        The following terms are applicable for any products that You purchased
        with Us.
      </p>
      <h2 className="text-lg font-poppins mb-2 font-semibold">
        Interpretation and Definitions
      </h2>
      <h3 className="text-lg font-poppins mb-2 font-semibold">
        Interpretation
      </h3>
      <p className="text-sm font-poppins mb-4 text-black">
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3 className="text-lg font-poppins mb-2 font-semibold">Definitions</h3>
      <p className="text-sm font-poppins mb-4 text-black">
        For the purposes of this Return and Refund Policy:
      </p>
      <ul className="list-disc px-10">
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Homeohopes.
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            <strong>Goods</strong> refer to the items offered for sale on the
            Service.
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            <strong>Orders</strong> mean a request by You to purchase Goods from
            Us.
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            <strong>Service</strong> refers to the Website.
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            <strong>Website</strong> refers to Homeohopes, accessible from{" "}
            <a href="https://www.dranoop.in/">https://www.dranoop.in/</a>
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
        </li>
      </ul>
      <h2 className="text-lg font-poppins mb-2 font-semibold">
        Your Order Cancellation Rights
      </h2>
      <p className="text-sm font-poppins mb-4 text-black">
        You are entitled to cancel Your Order within 7 days without giving any
        reason for doing so.
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        The deadline for cancelling an Order is 7 days from the date on which
        You received the Goods or on which a third party you have appointed, who
        is not the carrier, takes possession of the product delivered.
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        In order to exercise Your right of cancellation, You must inform Us of
        your decision by means of a clear statement. You can inform us of your
        decision by:
      </p>
      <ul className="list-disc px-10">
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            By email: homeohopes@gmail.com
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            By visiting this page on our website:{" "}
            <a href="https://www.dranoop.in/contact">
              https://www.dranoop.in/contact
            </a>
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            By phone number: +919425024526
          </p>
        </li>
      </ul>
      <p className="text-sm font-poppins mb-4 text-black">
        We will reimburse You no later than 14 days from the day on which We
        receive the returned Goods. We will use the same means of payment as You
        used for the Order, and You will not incur any fees for such
        reimbursement.
      </p>
      <h2 className="text-lg font-poppins mb-2 font-semibold">
        Conditions for Returns
      </h2>
      <p className="text-sm font-poppins mb-4 text-black">
        In order for the Goods to be eligible for a return, please make sure
        that:
      </p>
      <ul className="list-disc px-10">
        <li className="text-sm font-poppins mb-4">
          The Goods were purchased in the last 7 days
        </li>
        <li className="text-sm font-poppins mb-4">
          The Goods are in the original packaging
        </li>
      </ul>
      <p className="text-sm font-poppins mb-4 text-black">
        The following Goods cannot be returned:
      </p>
      <ul className="list-disc px-10">
        <li className="text-sm font-poppins mb-4">
          The supply of Goods made to Your specifications or clearly
          personalized.
        </li>
        <li className="text-sm font-poppins mb-4">
          The supply of Goods which according to their nature are not suitable
          to be returned, deteriorate rapidly or where the date of expiry is
          over.
        </li>
        <li className="text-sm font-poppins mb-4">
          The supply of Goods which are not suitable for return due to health
          protection or hygiene reasons and were unsealed after delivery.
        </li>
        <li className="text-sm font-poppins mb-4">
          The supply of Goods which are, after delivery, according to their
          nature, inseparably mixed with other items.
        </li>
      </ul>
      <p className="text-sm font-poppins mb-4 text-black">
        We reserve the right to refuse returns of any merchandise that does not
        meet the above return conditions in our sole discretion.
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        Only regular priced Goods may be refunded. Unfortunately, Goods on sale
        cannot be refunded. This exclusion may not apply to You if it is not
        permitted by applicable law.
      </p>
      <h2 className="text-lg font-poppins mb-2 font-semibold">
        Returning Goods
      </h2>
      <p className="text-sm font-poppins mb-4 text-black">
        You are responsible for the cost and risk of returning the Goods to Us.
        You should send the Goods at the following address:
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        G-4, Jeevan Sagar Apartment, Malviya Nagar, Bhopal - 462003 (Opposite
        Airtel Office)
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        We cannot be held responsible for Goods damaged or lost in return
        shipment. Therefore, We recommend an insured and trackable mail service.
        We are unable to issue a refund without actual receipt of the Goods or
        proof of received return delivery.
      </p>
      <h2 className="text-lg font-poppins mb-2 font-semibold">Gifts</h2>
      <p className="text-sm font-poppins mb-4 text-black">
        If the Goods were marked as a gift when purchased and then shipped
        directly to you, You'll receive a gift credit for the value of your
        return. Once the returned product is received, a gift certificate will
        be mailed to You.
      </p>
      <p className="text-sm font-poppins mb-4 text-black">
        If the Goods weren't marked as a gift when purchased, or the gift giver
        had the Order shipped to themselves to give it to You later, We will
        send the refund to the gift giver.
      </p>
      <h3 className="text-lg font-poppins mb-2 font-semibold">Contact Us</h3>
      <p className="text-sm font-poppins mb-4 text-black">
        If you have any questions about our Returns and Refunds Policy, please
        contact us:
      </p>
      <ul className="list-disc px-10">
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            By email: homeohopes@gmail.com
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            By visiting this page on our website:{" "}
            <a href="https://www.dranoop.in/contact">
              https://www.dranoop.in/contact
            </a>
          </p>
        </li>
        <li className="text-sm font-poppins mb-4">
          <p className="text-sm font-poppins mb-4 text-black">
            By phone number: +919425024526
          </p>
        </li>
      </ul>
    </div>
  );
}

export default RefundPolicy;
