import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ReceptionistMenu, adminMenu } from "../../Data/Menu";
import Menu from "./Menu";

const SidebarMenu = () => {
  const { logout, role } = useContext(AuthContext);

  const menuItems = role === "admin" ? adminMenu : ReceptionistMenu;

  return (
    <div className="flex flex-col px-3">
      {menuItems?.map((item, index) => {
        return <Menu menu={item} index={index} logout={logout} />;
      })}
    </div>
  );
};

export default SidebarMenu;
