// ContactPage.jsx
import { useFormik } from "formik";
import React from "react";
import { contactSchema } from "../validation/contactSchema";
import TextInput from "../components/core/TextInput";
import Textarea from "../components/core/Textarea";
import Button from "../components/core/Button";
import { postApi } from "../hooks/Api";
import { toast } from "react-toastify";

const Contact = () => {
  const {
    handleBlur,
    handleSubmit,
    handleReset,
    handleChange,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: contactSchema,
    onSubmit: async (values) => {
      const response = await postApi("/mail/contact", values);
      if (response.success) {
        toast.success(response.message);
        handleReset();
      } else {
        toast.error(response.message);
      }
    },
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100">
      <div className="w-full max-w-6xl space-y-8 bg-white rounded shadow-md">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div className="p-10">
            <h2 className="text-2xl font-semibold  text-gray-900 font-poppins">
              Contact Us
            </h2>
            <p className="mt-4 text-gray-700 font-poppins">
              Fill out the form below to send us a message.
            </p>
            <div className="mt-8 space-y-6">
              <TextInput
                id="name"
                label="Name"
                type="text"
                placeholder="Enter your name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.name}
                touched={touched.name}
              />
              <TextInput
                id="email"
                label="Email"
                type="email"
                placeholder="Enter your email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.email}
                touched={touched.email}
              />
              <TextInput
                id="subject"
                label="Subject"
                type="text"
                placeholder="Enter your subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.subject}
                touched={touched.subject}
              />
              <Textarea
                id="message"
                label="Message"
                type="text"
                placeholder="Enter your message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.message}
                touched={touched.message}
              />
              <Button
                title="Submit"
                className="w-full"
                onClick={handleSubmit}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-4 bg-primary p-8">
            <div>
              <h3 className="text-lg font-semibold text-gray-900 font-poppins  text-white">
                Contact Information
              </h3>
              <p className="mt-2  text-white font-poppins">
                You can also reach us through the following contact information:
              </p>
            </div>
            <div>
              <h6 className="font-medium text-white font-poppins text-sm">
                Merchant Legal entity name
              </h6>
              <p className="text-gray-700 font-poppins  text-white">
                Homeohopes
              </p>
            </div>
            <div>
              <h6 className="font-medium  text-white font-poppins text-sm">
                Registered Address
              </h6>
              <p className=" text-white font-poppins">
                G-4, Jeevan Sagar Apartment (Opposite Airtel Office), Malviya
                Nagar, Bhopal - 462003
              </p>
            </div>
            <div>
              <h6 className="font-medium text-white font-poppins text-sm">
                Operational Address
              </h6>
              <p className=" text-white font-poppins">
                G-4, Jeevan Sagar Apartment (Opposite Airtel Office), Malviya
                Nagar, Bhopal - 462003
              </p>
            </div>
            <div>
              <h6 className="font-medium  font-poppins  text-white text-sm">
                Telephone No
              </h6>
              <p className=" text-white font-poppins">+919425024526</p>
            </div>
            <div>
              <h6 className="font-medium  text-white font-poppins text-sm">
                E-Mail ID
              </h6>
              <p className=" text-white font-poppins">homeohopes@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
