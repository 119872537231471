import { Box, Snackbar, Alert } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { theme } from "../../theme/Theme";
import Text from "../core/Text";

function ProfileDocument(props) {
  const { Url, setUrl, message } = props;
  const [open, setopen] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUrl(acceptedFiles[0]);
      setopen(true);
    },
    [setUrl]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box className="">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setopen(false)}
      >
        <Alert
          onClose={() => setopen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          File Selected Successfully
        </Alert>
      </Snackbar>

      <Box className="flex justify-start mb-5">
        {Url ? (
          <Box>
            <Box
              sx={{
                flex: 1,
                borderRadius: 2,
                marginTop: 1,
              }}
            >
              <img
                src={typeof Url == "object" ? URL.createObjectURL(Url) : Url}
                alt=""
                style={{
                  width: 150,
                  height: 150,
                  objectFit: "fill",
                  margin: "auto",
                  borderRadius: 200,
                }}
              />
            </Box>
            <Text
              variant="contained"
              style={{
                width: "100%",
                marginTop: 2,
                textAlign: "center",
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
              onClick={() => setUrl("")}
            >
              Remove Image
            </Text>
          </Box>
        ) : (
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                borderWidth: 2,
                borderRadius: 2000,
                width: 150,
                height: 150,
                margin: "auto",
                backgroundColor: "#fafafa",
                color: "#bdbdbd",
                outline: "none",
                transition: "border .24s ease-in-out",
                marginTop: 1,
                textAlign: "center",
              }}
              className="border border-dashed"
            >
              <Text>{message ?? "Upload Image here..."}</Text>
            </Box>
          </Box>
        )}
      </Box>

      {props.errors && props.touched ? (
        <Text
          style={{
            color: "red",
            fontSize: 12,
            marginTop: 15,
          }}
        >
          {props.errors}
        </Text>
      ) : null}
    </Box>
  );
}

export default ProfileDocument;
