import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function Footer() {
  const { siteData } = useContext(AuthContext);
  return (
    <footer className="bg-gray-800 text-white py-8 bg-primary">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <div className="flex flex-row items-center space-x-4">
              <img
                src={siteData?.logo}
                alt=""
                className="w-10 h-10 object-contain rounded-full"
              />

              <h3 className="text-2xl font-semibold  font-poppins">
                Homeohopes
              </h3>
            </div>
            <p className="text-gray-400 mt-2 font-poppins">
              A Best Homeopathic Clinic
            </p>
          </div>

          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-3">Important Links</h3>
            <ul>
              <li className="mb-2">
                <Link
                  to="/terms-conditions"
                  className="text-gray-400 hover:text-white font-poppins text-sm"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/privacy-policy"
                  className="text-gray-400 hover:text-white font-poppins text-sm"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/refund-policy"
                  className="text-gray-400 hover:text-white font-poppins text-sm"
                >
                  Refund Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/contact"
                  className="text-gray-400 hover:text-white font-poppins text-sm"
                >
                  Contact Us
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/login"
                  className="text-gray-400 hover:text-white font-poppins text-sm"
                >
                  Admin Login
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-full md:w-1/3">
            <h3 className="text-xl font-semibold mb-3 font-poppins">
              Contact Us
            </h3>
            <p className="text-gray-400 mb-1 font-poppins">
              Email: homeohopes@gmail.com
            </p>
            <p className="text-gray-400 mb-1 font-poppins">Phone: 9425024526</p>
            <p className="text-gray-400 font-poppins">
              Address: G-4, Jeevan Sagar Apartment, Malviya Nagar, Bhopal -
              462003 (Opposite Airtel Office)
            </p>
          </div>
        </div>

        <div className="mt-8 text-center text-gray-500 font-poppins">
          <p>&copy; 2024 Homeohopes. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
