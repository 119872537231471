import React from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import Button from "../../components/core/Button";
import Textarea from "../../components/core/Textarea";
import DropdownSelect from "../../components/core/Dropdown";
import { paymentMode } from "../../Data/Options";

function Payment(props) {
  const { loading, formik } = props;
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = formik;

  return (
    <Container>
      <TextInput
        label="Amount"
        type="text"
        placeholder="Sum of Amount"
        value={values.medicineCharge + values.consultationFee}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.amount}
        errors={errors.amount}
        readOnly={true}
        name="amount"
        id="amount"
        maxLength={50}
        Icon={null}
      />

      <TextInput
        label="Transaction Id"
        placeholder="Enter transactionId"
        value={values.transactionId}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.transactionId}
        errors={errors.transactionId}
        name="transactionId"
        id="transactionId"
        maxLength={50}
        Icon={null}
        type="text"
      />

      <TextInput
        label="Consultation Fee"
        placeholder="Enter Consultation Fee"
        value={values.consultationFee}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.consultationFee}
        errors={errors.consultationFee}
        name="consultationFee"
        id="consultationFee"
        maxLength={50}
        Icon={null}
        type="number"
      />
      <TextInput
        label="Consultation Fee Valid (in Days)"
        placeholder="Enter Consultation Fee Validation (in Days)"
        value={values.consulationDays}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.consulationDays}
        errors={errors.consulationDays}
        name="consulationDays"
        id="consulationDays"
        maxLength={50}
        Icon={null}
        type="number"
      />

      <TextInput
        label="Medicine Charge"
        placeholder="Enter Medicine Charge"
        value={values.medicineCharge}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.medicineCharge}
        errors={errors.medicineCharge}
        name="medicineCharge"
        id="medicineCharge"
        maxLength={50}
        Icon={null}
        type="number"
      />

      <TextInput
        label="Medicine Charge  (in Days)"
        placeholder="Enter Medicine Charge  (in Days)"
        value={values.medicineDays}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.medicineDays}
        errors={errors.medicineDays}
        name="medicineDays"
        id="medicineDays"
        maxLength={50}
        Icon={null}
        type="number"
      />

      <DropdownSelect
        defaultValue="Select Payment Mode"
        value={values.mode}
        label="Payment Mode"
        options={paymentMode}
        onChange={(e) => {
          setFieldValue("mode", e.target.value);
        }}
        onBlur={handleBlur}
        touched={touched.mode}
        errors={errors.mode}
        name="mode"
        id="mode"
      />

      <Textarea
        label="Note (optional)"
        placeholder="Enter note"
        value={values.note}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.note}
        errors={errors.note}
        name="note"
        id="note"
        Icon={null}
        multiline={true}
      />

      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20, width: "100%" }}
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default Payment;
