import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import PublicRoute from "./PublicRoute";

function Route() {
  const { authorized, role } = useContext(AuthContext);

  const routes = (authorized, role) => {
    return createBrowserRouter([
      ...AuthRoute(authorized, role),
      ...PublicRoute(authorized, role),
    ]);
  };

  const routerConfig = routes(authorized, role);

  return <RouterProvider router={routerConfig} />;
}

export default Route;
