import React from "react";
import { Backdrop } from "@mui/material";
import { Player } from "video-react";
import Button from "../core/Button";

const ViewImage = ({ toggle, setToggle, url }) => {
  return (
    <Backdrop
      className="flex flex-1 justify-center items-center"
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={toggle}
      onClick={() => setToggle(false)}
      // Set to true to show the backdrop, or use a state variable
    >
      <div className="lg:w-9/12 w-full mx-auto bg-white p-2">
        <img src={url} />

        <Button
          title="Close"
          className="bg-primary text-white w-4/3 mt-5"
          onClick={() => {
            setToggle(false);
          }}
        />
      </div>
    </Backdrop>
  );
};

export default ViewImage;
