import React from "react";
import { Box } from "@mui/material";

const Container = ({ children, ...props }) => {
  return (
    <Box className="w-11/12 mx-auto mt-5 relative" {...props}>
      {children}
    </Box>
  );
};

export default Container;
