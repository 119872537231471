import React from "react";
import Container from "../../components/components/Container";
import Button from "../../components/core/Button";
import UploadDocument from "../../components/core/UploadDocument";
import Dpicker from "../../components/core/Dpicker";
import { diseases } from "../../Data/Options";
import DropdownSelect from "../../components/core/Dropdown";
import Textarea from "../../components/core/Textarea";

function Disease(props) {
  const { loading, formik } = props;
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = formik;

  return (
    <Container>
      <UploadDocument
        touched={touched.photo}
        errors={errors.photo}
        label="Upload Image"
        Url={values.photo}
        setUrl={(e) => {
          setFieldValue("photo", e);
        }}
      />
      <Textarea
        label="Description"
        placeholder="Enter Description"
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.description}
        errors={errors.description}
        name="description"
        id="description"
        Icon={null}
        multiline={true}
      />
      <Textarea
        label="Optional"
        placeholder="Enter optional"
        value={values.optional}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.optional}
        errors={errors.optional}
        name="optional"
        id="optional"
        Icon={null}
        multiline={true}
      />

      <Dpicker
        label="Since"
        placeholder="Since"
        value={values.since}
        onChange={(e) => {
          setFieldValue("since", e);
        }}
        onBlur={handleBlur}
        touched={touched.since}
        errors={errors.since}
        name="since"
        id="since"
        maxLength={10}
        Icon={null}
      />

      <DropdownSelect
        defaultValue="Select Diseases Type"
        value={values.type}
        label="Disease Types"
        options={diseases}
        onChange={(e) => {
          setFieldValue("type", e.target.value);
        }}
        onBlur={handleBlur}
        touched={touched.type}
        errors={errors.type}
        name="type"
        id="type"
      />

      <UploadDocument
        touched={touched.video}
        errors={errors.video}
        label="Upload Video"
        message="Upload video here..."
        Url={values.video}
        setUrl={(e) => {
          setFieldValue("video", e);
        }}
      />

      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20, width: "100%" }}
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default Disease;
