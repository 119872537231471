import React from 'react'
import Container from '../../components/components/Container';
import TextWithDescription from '../../components/components/TextWithDescription';

function Disease(props) {
    const { data } = props;
    return (
        <Container>
            <TextWithDescription heading={"Name"} value={data?.name} />
            <TextWithDescription heading={"Symptoms"} value={data?.symptoms} />
            <TextWithDescription heading={"Cause"} value={data?.causes} />
            <TextWithDescription heading={"Description"} value={data?.description} />
        </Container>
    )
}

export default Disease