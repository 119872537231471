import React, { useContext, useEffect, useState } from "react";
import DashboardCard from "../../components/components/DashboardCard";
import Container from "../../components/components/Container";
import { postApi } from "../../hooks/Api";
import { dashboardIcons } from "../../Data/Icon";
import { AuthContext } from "../../context/AuthContext";
import DropdownSelect from "../../components/core/Dropdown";
import { times } from "../../Data/Options";

function Dashboard() {
  const [data, setdata] = useState({});
  const [time, settime] = useState(1);
  const { siteData } = useContext(AuthContext);

  const getData = async () => {
    const res = await postApi("user/dashboard", {
      time: time,
    });
    setdata(res?.data?.dashboardData);
  };

  useEffect(() => {
    getData();
    return () => {
      getData();
    };
  }, []);

  useEffect(() => {
    getData();
  }, [time]);

  return (
    <Container>
      <div className="my-10 flex flex-row justify-between items-center flex-wrap ">
        <p className="text-3xl font-bold  font-poppins">
          Welcome , {siteData?.name}
        </p>
        <DropdownSelect
          className="w-72"
          options={times}
          value={siteData?.clinicId}
          onChange={(e) => {
            settime(e.target.value);
          }}
          name="clinicId"
          id="clinicId"
          defaultValue="Select Clinic"
        />
      </div>
      <div className="grid xl:grid-cols-4  lg:grid-cols-3 md:grid-cols-2   gap-4 mt-3">
        {Object.keys(data).map((key, index) => {
          return (
            <DashboardCard
              key={index}
              title={key}
              count={data[key]}
              index={index}
              levelDown={false}
              levelUp={true}
              rate={5.8}
              icon={dashboardIcons[index]}
            />
          );
        })}
      </div>
    </Container>
  );
}

export default Dashboard;
