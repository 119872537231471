import React, { useEffect, useState } from "react";
import Container from "../../components/components/Container";
import Header from "../../components/components/Header";
import Sidebar from "../../components/core/Sidebar";
import { Box, TableRow } from "@mui/material";
import TabCell from "../../components/core/TabCell";
import Table from "../../components/core/Table";
import { VisibilityIcon } from "../../Data/Icon";
import formateDate from "../../hooks/formateDate";
import ViewDisease from "../../Forms/View/Disease";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../hooks/Api";

function Diseases() {
  const [viewToggle, setviewToggle] = useState(false);
  const [state, setState] = useState([]);
  const [currentpage, setcurrentPage] = useState(1);
  const [nextPage, setnextPage] = useState(null);
  const [previousPage, setpreviousPage] = useState(null);
  const [totalPages, settotalPages] = useState(0);
  const [data, setdata] = useState({});
  const navigate = useNavigate();

  const getData = async () => {
    const response = await postApi("disease/get", {
      page: currentpage,
      size: 15,
    });
    setState(response?.data?.data);
    setnextPage(response?.data?.nextPage);
    setpreviousPage(response?.data?.previousPage);
    settotalPages(response?.data?.totalPages);
  };
  const handlePageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  useEffect(() => {
    getData();
  }, [currentpage]);

  const tableHeaders = [
    { name: "Serial No.", id: "Serial" },
    { name: "Diseases Id", id: "diseasesId" },
    { name: "Name", id: "Contact" },
    { name: "Symptoms", id: "symptoms" },
    { name: "Cause", id: "cause" },
    { name: "Created At", id: "createdAt" },
    { name: "Action", id: "action" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>{row?.diseaseId}</TabCell>
        <TabCell>{row?.name}</TabCell>
        <TabCell>{row?.symptoms.slice(0, 50)}...</TabCell>
        <TabCell>{row?.causes.slice(0, 50)}...</TabCell>
        <TabCell>{formateDate(row?.createdAt)}</TabCell>
        <TabCell>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setdata(row);
              setviewToggle(true);
            }}
          >
            <VisibilityIcon />
          </Box>
        </TabCell>
      </TableRow>
    );
  };

  return (
    <Container>
      <Header
        title="Diseases"
        subtitle="Here you can see the list of disease "
        btnTitle="Add Diseases"
        filterTitle="Filter Diseases"
        addClick={() => navigate("/diseases/add")}
      />

      <Table
        extra={{
          rows: state ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
          handlePageChange: handlePageChange,
          nextPage: nextPage,
          previousPage: previousPage,
          totalPages: totalPages,
          currentpage: currentpage,
        }}
      />
      <Sidebar
        label="View Disease"
        description="View details of the disease"
        setToggle={setviewToggle}
        Toggle={viewToggle}
      >
        <ViewDisease data={data} />
      </Sidebar>
    </Container>
  );
}

export default Diseases;
