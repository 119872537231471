import HomeIcon from "@mui/icons-material/Home";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import PersonIcon from "@mui/icons-material/Person";
import CleanHandsIcon from "@mui/icons-material/CleanHands";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import WebStoriesIcon from "@mui/icons-material/WebStories";

const ReceptionistMenu = [
  {
    label: "Dashboard",
    icon: <HomeIcon fontSize="small" className="icon" />,
    to: "/",
  },
  {
    label: "Appointments",
    icon: <BusinessCenterIcon fontSize="small" className="icon" />,
    to: "/appointments",
  },
  {
    label: "Patients",
    icon: <PersonIcon fontSize="small" className="icon" />,
    to: "/patients",
  },
  {
    label: "Logout",
    icon: <ExitToAppIcon fontSize="small" className="icon" />,
    to: "/login",
  },
];

const adminMenu = [
  {
    label: "Dashboard",
    icon: <HomeIcon fontSize="small" className="icon" />,
    to: "/",
  },
  {
    label: "Appointments",
    icon: <BusinessCenterIcon fontSize="small" className="icon" />,
    to: "/appointments",
  },
  {
    label: "Patients",
    icon: <PersonIcon fontSize="small" className="icon" />,
    to: "/patients",
  },
  {
    label: "Feedback",
    icon: <StarOutlineIcon fontSize="small" className="icon" />,
    to: "/feedback",
  },
  {
    label: "Invoice",
    icon: <MoneyOutlinedIcon fontSize="small" className="icon" />,
    to: "/revenue",
  },
  {
    label: "Carousel",
    icon: <WebStoriesIcon fontSize="small" className="icon" />,
    to: "/courasel",
  },
  {
    label: "Transactions",
    icon: <MoneyOutlinedIcon fontSize="small" className="icon" />,
    to: "/transactions",
  },
  {
    label: "Diseases",
    icon: <CleanHandsIcon fontSize="small" className="icon" />,
    to: "/diseases",
  },
  {
    label: "Manage Staff",
    icon: <PeopleOutlineIcon fontSize="small" className="icon" />,
    to: "/staff",
  },
  {
    label: "Setting",
    icon: <SettingsOutlinedIcon fontSize="small" className="icon" />,
    to: "/setting",
  },
  {
    label: "Logout",
    icon: <ExitToAppIcon fontSize="small" className="icon" />,
    to: "/login",
  },
];

export { ReceptionistMenu, adminMenu };
