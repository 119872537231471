import React from "react";
import NotFoundPage from "../pages/NotFound";

export default function AdminRoute(props) {
  return {
    path: props.path,
    element: props.role === "admin" ? props.element : <NotFoundPage />,
    children: props.children,
  };
}
