import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import StarRateIcon from "@mui/icons-material/StarRate";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import KeyIcon from "@mui/icons-material/Key";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupIcon from "@mui/icons-material/Group";
import PaidIcon from "@mui/icons-material/Paid";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import FeedbackIcon from "@mui/icons-material/Feedback";

const dashboardIcons = [
  <PersonIcon className=" text-gray" />,
  <GroupIcon className=" text-gray" />,
  <CalendarMonthIcon className=" text-gray" />,
  <PaidIcon className=" text-gray" />,
  <MedicalServicesIcon className=" text-gray" />,
  <CoronavirusIcon className=" text-gray" />,
  <FeedbackIcon className=" text-gray" />,
];

export {
  AddIcon,
  FilterAltIcon,
  VisibilityIcon,
  StarRateIcon,
  DownloadingOutlinedIcon,
  MenuIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  KeyIcon,
  EmailIcon,
  PersonIcon,
  CalendarMonthIcon,
  dashboardIcons,
};
