import React from "react";
import Navbar from "../components/home/Navbar";
import Hero from "../components/home/Hero";
import Number from "../components/home/Number";
import Offer from "../components/home/Offer";
import Review from "../components/home/Review";
import Outline from "../components/home/Outline";
import Faq from "../components/home/Faq";
import Footer from "../components/home/Footer";
import About from "../components/home/About";

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Offer />
      <Number />
      <Review />
      <Outline />
      <Faq />
      <Footer />
    </div>
  );
}

export default Home;
