import React from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import DropdownSelect from "../../components/core/Dropdown";
import Dpicker from "../../components/core/Dpicker";
import Button from "../../components/core/Button";

function PhysicalStats(props) {
  const { loading, formik } = props;
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = formik;

  return (
    <Container>
      <TextInput
        label="Weight"
        type="number"
        placeholder="Enter Weight"
        value={values.weight}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.weight}
        errors={errors.weight}
        name="weight"
        id="weight"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="BMI"
        type="number"
        placeholder="Enter BMI"
        value={values.bmi}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.bmi}
        errors={errors.bmi}
        name="bmi"
        id="bmi"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Body Fat"
        type="number"
        placeholder="Enter Body Fat"
        value={values.bodyFat}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.bodyFat}
        errors={errors.bodyFat}
        name="bodyFat"
        id="bodyFat"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Fat-Free Weight"
        type="number"
        placeholder="Enter Fat-Free Weight"
        value={values.fat_freeWeight}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.fat_freeWeight}
        errors={errors.fat_freeWeight}
        name="fat_freeWeight"
        id="fat_freeWeight"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Subcutaneous Fat"
        type="number"
        placeholder="Enter Subcutaneous Fat"
        value={values.subcutaneousFat}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.subcutaneousFat}
        errors={errors.subcutaneousFat}
        name="subcutaneousFat"
        id="subcutaneousFat"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Visceral Fat"
        type="number"
        placeholder="Enter Visceral Fat"
        value={values.visceralFat}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.visceralFat}
        errors={errors.visceralFat}
        name="visceralFat"
        id="visceralFat"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Body Water"
        type="number"
        placeholder="Enter Body Water"
        value={values.bodyWater}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.bodyWater}
        errors={errors.bodyWater}
        name="bodyWater"
        id="bodyWater"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Skeletal Muscle"
        type="number"
        placeholder="Enter Skeletal Muscle"
        value={values.skeletalMuscle}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.skeletalMuscle}
        errors={errors.skeletalMuscle}
        name="skeletalMuscle"
        id="skeletalMuscle"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Muscle Mass"
        type="number"
        placeholder="Enter Muscle Mass"
        value={values.muscleMass}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.muscleMass}
        errors={errors.muscleMass}
        name="muscleMass"
        id="muscleMass"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Bone Mass"
        type="number"
        placeholder="Enter Bone Mass"
        value={values.boneMass}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.boneMass}
        errors={errors.boneMass}
        name="boneMass"
        id="boneMass"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Protein"
        type="number"
        placeholder="Enter Protein"
        value={values.protein}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.protein}
        errors={errors.protein}
        name="protein"
        id="protein"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="BMR"
        type="number"
        placeholder="Enter BMR"
        value={values.bmr}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.bmr}
        errors={errors.bmr}
        name="bmr"
        id="bmr"
        maxLength={10}
        Icon={null}
      />

      <TextInput
        label="Metabolic Age"
        type="number"
        placeholder="Enter Metabolic Age"
        value={values.metabolicAge}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.metabolicAge}
        errors={errors.metabolicAge}
        name="metabolicAge"
        id="metabolicAge"
        maxLength={10}
        Icon={null}
      />

      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20, width: "100%" }}
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default PhysicalStats;
