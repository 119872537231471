import React, { useEffect, useState } from "react";
import Table from "../../../components/core/Table";
import { TableRow } from "@mui/material";
import Sidebar from "../../../components/core/Sidebar";
import { useFormik } from "formik";
import { getApi, postApi } from "../../../hooks/Api";
import { toast } from "react-toastify";
import TabCell from "../../../components/core/TabCell";
import formateDate from "../../../hooks/formateDate";
import { VisibilityIcon } from "../../../Data/Icon";
import Diseases from "../../../Forms/Add/Diseases";
import Button from "../../../components/core/Button";
import patientDisease from "../../../validation/patientDisease";
import EyeAction from "../../../components/components/EyeAction";
import VideoComponent from "../../../components/components/ViewVideo";
import ViewImage from "../../../components/components/ViewImage";

function Diseasess() {
  const [Toggle, setToggle] = useState(false);
  const [loading, setloading] = useState(false);
  const [patient, setpatient] = useState([]);
  const [data, setdata] = useState({});
  const [VideoToggle, setVideoToggle] = useState(false);
  const [ImageToggle, setImageToggle] = useState(false);

  const fetch = async () => {
    const response = await getApi("patientDisease");
    setpatient(response.diseases);
  };

  useEffect(() => {
    fetch();
  }, []);

  const formik = useFormik({
    initialValues: {
      photo: "",
      description: "",
      optional: "",
      video: "",
      since: "",
      type: "",
    },
    validationSchema: patientDisease,
    onSubmit: async (values) => {
      setloading(true);

      const formData = new FormData();
      formData.append("photo", values.photo);
      formData.append("description", values.description);
      formData.append("optional", values.optional);
      formData.append("video", values.video);
      formData.append("since", values.since);
      formData.append("type", values.type);

      const response = await postApi("patientDisease", formData);
      if (response?.success) {
        toast.success(response?.message);
        setToggle(false);
      } else {
        toast.error(response?.message);
      }
      setloading(false);
    },
  });

  const tableHeaders = [
    { name: "Id", id: "Serial" },
    { name: "description", id: "Description" },
    { name: "optional", id: "Optional" },
    { name: "since", id: "Since" },
    { name: "photo", id: "Photo" },
    { name: "video", id: "Video" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>{row?.description}</TabCell>
        <TabCell>{row?.optional}</TabCell>
        <TabCell>{formateDate(row?.since)}</TabCell>
        <TabCell>
          <EyeAction
            onClick={() => {
              setdata({
                url: row?.photo,
              });
              setImageToggle(true);
            }}
          >
            <VisibilityIcon />
          </EyeAction>
        </TabCell>
        <TabCell>
          <EyeAction
            onClick={() => {
              setVideoToggle(true);
              setdata({
                url: row?.video,
              });
            }}
          >
            <VisibilityIcon />
          </EyeAction>
        </TabCell>
      </TableRow>
    );
  };
  return (
    <div className="">
      <div className="flex flex-row justify-between">
        <div></div>
        <Button
          title="Add Diseases"
          className="bg-primary text-white  "
          onClick={() => {
            setToggle(true);
          }}
        />
      </div>

      <Table
        extra={{
          rows: patient ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
        }}
      />

      <VideoComponent
        toggle={VideoToggle}
        setToggle={setVideoToggle}
        url={data?.url}
      />
      <ViewImage
        toggle={ImageToggle}
        setToggle={setImageToggle}
        url={data?.url}
      />

      <Sidebar
        Toggle={Toggle}
        setToggle={setToggle}
        label="Add Diseases"
        description="Here you can create new appointment."
      >
        <Diseases formik={formik} loading={loading} />
      </Sidebar>
    </div>
  );
}

export default Diseasess;
