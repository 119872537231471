import React, { useEffect, useState } from "react";
import Table from "../../../components/core/Table";
import { Box, TableRow } from "@mui/material";
import Sidebar from "../../../components/core/Sidebar";
import { useFormik } from "formik";
import { getApi, postApi } from "../../../hooks/Api";
import { toast } from "react-toastify";
import TabCell from "../../../components/core/TabCell";
import formateDate from "../../../hooks/formateDate";
import { VisibilityIcon } from "../../../Data/Icon";
import { useNavigate, useParams } from "react-router-dom";
import AddReport from "../../../Forms/Add/Report";
import Button from "../../../components/core/Button";
import patientDisease from "../../../validation/patientDisease";
import EyeAction from "../../../components/components/EyeAction";
import VideoComponent from "../../../components/components/ViewVideo";
import ViewImage from "../../../components/components/ViewImage";
import reportSchema from "../../../validation/reportSchema";

function Report() {
  const { id } = useParams();
  const [Toggle, setToggle] = useState(false);
  const [loading, setloading] = useState(false);
  const [patient, setpatient] = useState([]);
  const [data, setdata] = useState({});
  const [VideoToggle, setVideoToggle] = useState(false);
  const [ImageToggle, setImageToggle] = useState(false);

  const fetch = async () => {
    const response = await postApi("report/get", {
      patientId: 1,
    });
    setpatient(response.data?.Reports);
  };

  useEffect(() => {
    fetch();
  }, []);

  const formik = useFormik({
    initialValues: {
      patientId: id, // Initialize with the default patient ID // Initialize with the default report type
      reportDate: "", // Initialize with the default report date
      description: "", // Initialize with the default description
      url: "", // Initialize with the default URL
      name: "", // Initialize with the default name
    },
    validationSchema: reportSchema,
    onSubmit: async (values) => {
      setloading(true);

      const formData = new FormData();
      formData.append("description", values.description);
      formData.append("reportDate", values.reportDate);
      formData.append("url", values.url);
      formData.append("name", values.name);
      formData.append("patientId", values.patientId);

      const response = await postApi("report", formData);
      if (response?.success) {
        toast.success(response?.message);
        setToggle(false);
      } else {
        toast.error(response?.message);
      }
      setloading(false);
    },
  });

  const tableHeaders = [
    { name: "Id", id: "Serial" },
    { name: "Name", id: "name" },
    { name: "description", id: "Description" },
    { name: "Date", id: "date" },
    { name: "Image", id: "image" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>{row?.name}</TabCell>
        <TabCell>{row?.description}</TabCell>
        <TabCell>{formateDate(row?.reportDate)}</TabCell>
        <TabCell>
          <EyeAction
            onClick={() => {
              setdata({
                url: row?.url,
              });
              setImageToggle(true);
            }}
          >
            <VisibilityIcon />
          </EyeAction>
        </TabCell>
      </TableRow>
    );
  };
  return (
    <div className="">
      <div className="flex flex-row justify-between">
        <div></div>
        <Button
          title="Add Report"
          className="bg-primary text-white  "
          onClick={() => {
            setToggle(true);
          }}
        />
      </div>

      <Table
        extra={{
          rows: patient ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
        }}
      />

      <VideoComponent
        toggle={VideoToggle}
        setToggle={setVideoToggle}
        url={data?.url}
      />
      <ViewImage
        toggle={ImageToggle}
        setToggle={setImageToggle}
        url={data?.url}
      />

      <Sidebar
        Toggle={Toggle}
        setToggle={setToggle}
        label="Add Report"
        description="Here you can create new report."
      >
        <AddReport formik={formik} loading={loading} />
      </Sidebar>
    </div>
  );
}

export default Report;
