const Yup = require("yup");

const reportSchema = Yup.object().shape({
  reportDate: Yup.string().required("Report date is required"),
  description: Yup.string().required("Description is required"),
  url: Yup.string().required("Report is required"), // Optionally add URL validation
  name: Yup.string().required("Name is required"),
});

export default reportSchema;
