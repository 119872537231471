import React, { useEffect, useState } from "react";
import Container from "../../components/components/Container";
import Header from "../../components/components/Header";
import Sidebar from "../../components/core/Sidebar";
import { postApi } from "../../hooks/Api";
import { TableRow } from "@mui/material";
import TabCell from "../../components/core/TabCell";
import Table from "../../components/core/Table";
import formateDate from "../../hooks/formateDate";
import ViewStaff from "../../Forms/View/Staff";
import EyeAction from "../../components/components/EyeAction";
import { useNavigate } from "react-router-dom";

function Staff() {
  // const [FilterToggle, setFilterToggle] = useState(false);

  const [viewStaff, setviewStaff] = useState(false);
  const [Data, setData] = useState({});

  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [currentpage, setcurrentPage] = useState(1);
  const [nextPage, setnextPage] = useState(null);
  const [previousPage, setpreviousPage] = useState(null);
  const [totalPages, settotalPages] = useState(0);

  const getData = async () => {
    const response = await postApi("user/get", {
      page: currentpage,
      size: 15,
    });
    setState(response?.data?.data);
    setnextPage(response?.data?.nextPage);
    setpreviousPage(response?.data?.previousPage);
    settotalPages(response?.data?.totalPages);
  };
  const handlePageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  useEffect(() => {
    getData();
  }, [currentpage]);

  const tableHeaders = [
    { name: "Staffs Id", id: "StaffsId" },
    { name: "Name", id: "name" },
    { name: "Email", id: "email" },
    { name: "Phone", id: "phone" },
    { name: "Address", id: "adddress" },
    { name: "Role", id: "role" },
    { name: "Created At", id: "createdAt" },
    { name: "Action", id: "action" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        style={{ width: "80%" }}
        key={index}
      >
        <TabCell>{row?.userId}</TabCell>
        <TabCell>{row?.name}</TabCell>
        <TabCell>{row?.email}</TabCell>
        <TabCell>{row?.phone}</TabCell>
        <TabCell> {row?.address}</TabCell>
        <TabCell>{row?.admin ? "Admin" : "Assistant"}</TabCell>
        <TabCell>{formateDate(row?.dateOfJoining)}</TabCell>
        <TabCell>
          <EyeAction
            onClick={() => {
              setviewStaff(true);
              setData(row);
            }}
          />
        </TabCell>
      </TableRow>
    );
  };

  return (
    <Container>
      <Header
        title="Staff"
        subtitle="Here you can see the list of Staff "
        btnTitle="Add Staff"
        filterTitle="Filter Staff"
        addClick={() => navigate("/staff/add")}
      />

      <Table
        extra={{
          rows: state ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
          handlePageChange: handlePageChange,
          nextPage: nextPage,
          previousPage: previousPage,
          totalPages: totalPages,
          currentpage: currentpage,
        }}
      />

      <Sidebar
        label="View Staff"
        description="View the data for the staff"
        setToggle={setviewStaff}
        Toggle={viewStaff}
      >
        <ViewStaff data={Data} />
      </Sidebar>
    </Container>
  );
}

export default Staff;
