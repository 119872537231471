const Yup = require("yup");

const historySchema = Yup.object().shape({
  relationship: Yup.string().required("Relationship is required"),
  name: Yup.string().required("Name is required"),
  since: Yup.date().required("Since date is required"),
  description: Yup.string().required(""), // Optionally add validation rules for description
});

export default historySchema;
