import React, { useEffect, useState } from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import { useFormik } from "formik";
import Button from "../../components/core/Button";
import settingSchema from "../../validation/settingSchema";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import { getApi, postApi } from "../../hooks/Api";
import ProfileDocument from "../../components/components/ProfileDocument";

function Setting() {
  const [loading, setloading] = useState(false);

  // Use useFormik hook to handle form state and validation
  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      clinicName: "",
      name: "",
      logo: "",
      appointmentfees: "",
      email: "",
      phone: "",
      address: "",
    },
    validationSchema: settingSchema,
    onSubmit: async () => {
      setloading(true);
      const formData = new FormData();

      formData.append("clinicName", values.clinicName);
      formData.append("name", values.name);
      formData.append("logo", values.logo);
      formData.append("appointmentfees", values.appointmentfees);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("address", values.address);

      const response = await postApi("setting", formData);
      if (response?.success) {
        toast.success(response?.message);
      } else {
        toast.error(response.message);
      }
      setloading(false);
    },
  });

  const fetch = async () => {
    const response = await getApi("setting");
    const { settings } = response;

    setValues({
      clinicName: settings.clinicName,
      name: settings.name,
      logo: settings.logo,
      appointmentfees: settings.appointmentfees,
      email: settings.email,
      phone: settings.phone,
      address: settings.address,
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ProfileDocument
            label="Upload Logo"
            message="Upload logo here..."
            onBlur={handleBlur}
            Url={values.logo}
            touched={touched.logo}
            errors={errors.logo}
            setUrl={(e) => {
              setFieldValue("logo", e);
            }}
            name="logo"
            id="logo"
          />

          <TextInput
            label="Clinic Name"
            name="clinicName"
            value={values.clinicName}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.clinicName}
            touched={touched.clinicName}
            placeholder="Enter clinic Name"
          />

          <TextInput
            label="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.name}
            touched={touched.name}
            placeholder="Enter your name"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextInput
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.email}
            touched={touched.email}
            placeholder="Enter Email"
          />

          <TextInput
            label="Phone"
            name="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.phone}
            touched={touched.phone}
            placeholder="Enter Phone Number"
            type="number"
            maxlength={10}
          />

          <TextInput
            label="Address"
            name="address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.address}
            touched={touched.address}
            placeholder="Enter Address"
          />

          <TextInput
            label="Appointment Fees"
            name="appointmentfees"
            value={values.appointmentfees}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.appointmentfees}
            touched={touched.appointmentfees}
            placeholder="Enter Appointment Fees"
            type="number"
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        title="Update Information"
        className="w-full md:w-40 mt-5"
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default Setting;
