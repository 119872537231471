import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box } from "@mui/material";
function EyeAction(props) {
  return (
    <Box {...props}>
      <VisibilityOutlinedIcon />
    </Box>
  );
}

export default EyeAction;
