import React from "react";

function Number() {
  const images = [
    {
      id: 1,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMUMlQcVAEJDSU9lRd4nIXcezjIMy4_wjOWQ&s",
      title: "Happy Patients",
      description: "from the various sources",
      number: "1000",
    },
    {
      id: 1,
      image: "https://bee-logical.com/wp-content/uploads/2022/11/Team-1.png",
      title: "Experience",
      description: "20+ years of Experience",
      number: "20",
    },
    {
      id: 1,
      image:
        "https://img.freepik.com/free-vector/3d-metal-star-isolated_1308-117760.jpg",
      title: "Rating",
      description: "  Loved by the Patient",
      number: 4.8,
    },
  ];
  return (
    <div className="py-[6%] lg:py-[4%] container mx-auto px-6" id="number">
      <p className="text-center  font-bold  font-poppins lg:text-4xl text-2xl">
        Our Application Stats
      </p>
      <p className="text-center  font-normal  font-poppins text-base mt-5 ">
        This application is cross-platform, other things like ratings and
        download figures are given below.
      </p>

      <div className="grid lg:grid-cols-3 gap-8 my-[20%] lg:my-[5%]  ">
        {images.map((data, index) => {
          return (
            <div
              key={index}
              className="w-full h-full bg-white items-center justify-center flex flex-col rounded-lg py-10 shadow-lg"
            >
              <img
                src={data.image}
                alt={data.name}
                className="w-36 h-36 object-contain"
              />
              <p className="text-center  text-xl font-medium mt-4 font-poppins">
                {data.title}
              </p>
              <p className="text-center text-black text-5xl font-semibold my-4 font-poppins">
                {data.number} +
              </p>
              <p className="text-lg my-3 font-medium font-poppins">
                {data.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Number;
