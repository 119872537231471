import * as Yup from "yup";

const settingSchema = Yup.object().shape({
  clinicName: Yup.string().required("Clinic Name is required"),
  logo: Yup.string().required("Logo is required"),
  name: Yup.string().required("Name is required"),
  appointmentfees: Yup.number()
    .required("Appointment fee is required")
    .positive()
    .integer()
    .min(0)
    .max(99999),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone Number is requires"),
  address: Yup.string().required("Adress is required"),
});

export default settingSchema;
