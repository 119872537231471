import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/core/Sidebar";
import Payment from "../../../Forms/Add/Payment";
import { useFormik } from "formik";
import { postApi } from "../../../hooks/Api";
import { toast } from "react-toastify";
import { TableRow } from "@mui/material";
import TabCell from "../../../components/core/TabCell";
import Table from "../../../components/core/Table";
import { DownloadingOutlinedIcon } from "../../../Data/Icon";
import formateDate from "../../../hooks/formateDate";
import { paymentSchema } from "../../../validation/paymentSchema";
import Button from "../../../components/core/Button";
import { Link, useParams } from "react-router-dom";
import { paymentMode } from "../../../Data/Options";
import { constant } from "../../../utils/constant";

function UserPayment() {
  const { id } = useParams();
  const [Toggle, setToggle] = useState(false);
  // const [FilterToggle, setFilterToggle] = useState(false);
  const [loading, setloading] = useState(false);
  const [payments, setPayment] = useState([]);

  const formik = useFormik({
    initialValues: {
      patientId: id,
      amount: "",
      transactionId: "",
      consultationFee: "",
      consulationDays: "",
      medicineCharge: "",
      medicineDays: "",
      mode: "",
      note: "",
    },
    validationSchema: paymentSchema,
    onSubmit: async (values) => {
      setloading(true);
      const response = await postApi("payment", {
        ...values,
        amount: values?.medicineCharge + values?.consultationFee,
        mode: paymentMode.filter(
          (value) => value.id === parseInt(values.mode)
        )[0].name,
      });
      if (response?.success) {
        toast.success(response?.message);
        setToggle(false);
      } else {
        toast.error(response.message);
      }
      setloading(false);
    },
  });

  const fetchData = async () => {
    const response = await postApi("payment/get", {
      patientId: id,
    });
    setPayment(response.data?.payments);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tableHeaders = [
    { name: "Serial No.", id: "Serial" },
    { name: "Payments Id", id: "PaymentsId" },
    { name: "Medicine Charge", id: "medicineCharge" },
    { name: "Consultation Fee", id: "consultationFee" },
    { name: "Amount", id: "amount" },
    { name: "Mode", id: "mode" },
    { name: "Created At", id: "createdAt" },
    { name: "Action", id: "action" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>{row?.paymentId}</TabCell>
        <TabCell>{row?.medicineCharge}</TabCell>
        <TabCell>{row?.consultationFee}</TabCell>
        <TabCell> ₹ {row?.amount}</TabCell>
        <TabCell>{row?.mode}</TabCell>
        <TabCell>{formateDate(row?.createdAt)}</TabCell>
        <TabCell>
          <Link
            sx={{ cursor: "pointer" }}
            to={`${constant.baseUrl}/payment/pdf/${row?.paymentId}`}
            target="_blank"
          >
            <DownloadingOutlinedIcon />
          </Link>
        </TabCell>
      </TableRow>
    );
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div></div>
        <Button
          title="Add Payment"
          className="bg-primary text-white  "
          onClick={() => {
            setToggle(true);
          }}
        />
      </div>
      <Table
        extra={{
          rows: payments ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
        }}
      />

      <Sidebar
        label="Add Payment"
        description="Add new Payments to the system"
        setToggle={setToggle}
        Toggle={Toggle}
      >
        <Payment formik={formik} loading={loading} />
      </Sidebar>
    </div>
  );
}

export default UserPayment;
