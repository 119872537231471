import React from "react";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function Outline() {
  return (
    <div className="container   gap-8 px-6 mx-auto " id="contact">
      <div className="rounded-md shadow-md border border-slate-200 grid grid-cols-1 lg:grid-cols-2 place-items-stretch   ">
        <div className="w-full md:w-2/3 px-8 py-[6%]">
          <h3 className="text-2xl font-bold mb-5 font-poppins  text-center sm:text-left">
            Contact Us
          </h3>
          <h2 className="text-4xl font-bold mb-3 font-poppins text-primary ">
            Dr Anoop Choudhary
          </h2>
          <p className="text-gray-400 mb-1 font-poppins ">
            Email: homeohopes@gmail.com
          </p>
          <p className="text-gray-400 mb-1 font-poppins ">Phone: 9425024526</p>
          <p className="text-gray-400 font-poppins ">
            Address: G-4, Jeevan Sagar Apartment, Malviya Nagar, Bhopal - 462003
            (Opposite Airtel Office)
          </p>

          <div className="flex flex-row flex-wrap justify-start items-center mt-10 gap-4">
            <Link
              to="tel:+919425024526"
              target="_blank"
              className=" bg-secondary px-8 py-3 rounded-sm flex flex-row items-center justify-center space-x-2 "
            >
              <p className="text-sm font-semibold text-white font-poppins ">
                Call Us
              </p>
              <CallIcon className="text-white" fontSize="small" />
            </Link>
            <Link
              to="/book-appointment"
              className=" bg-primary px-8 py-3 rounded-sm flex flex-row items-center justify-center space-x-2 "
            >
              <p className="text-sm font-semibold text-white font-poppins ">
                Book an Appointment
              </p>
              <CalendarMonthIcon className="text-white" fontSize="medium" />
            </Link>
          </div>
        </div>

        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1833.042119810605!2d77.40479808501397!3d23.240021439412672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c42970587432b%3A0x892531a4abfc0f8e!2sBEST%20HOMEOPATHIC%20DOCTOR%20IN%20BHOPAL%2CBEST%20HOMEOPATHIC%20DOCTOR%20FOR%20SKIN%2CBEST%20HOMEOPATHIC%20CLINIC%2CBEST%20HOMEOPATHIC%20DOCTOR%20FOR%20HAIR%20TREATMENT!5e0!3m2!1sen!2sin!4v1721922486056!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="border border-slate-200 rounded-sm"
        ></iframe>
      </div>
    </div>
  );
}

export default Outline;
