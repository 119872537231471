import React from "react";

const FormContainer = ({ children, ...props }) => {
  return (
    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 lg:gap-x-8 gap-x-4 flex-1">
      {children}
    </div>
  );
};

export default FormContainer;
