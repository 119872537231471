import React, { useState } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import UploadDocument from "../../components/core/UploadDocument";
import Loading from "../../components/core/Loading";
import { postApi } from "../../hooks/Api";
import Button from "../../components/core/Button";

function AddImages(props) {
  const [Url, setUrl] = useState("");
  const [loading, setloading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setloading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("file", Url);

    const result = await postApi("courasel", bodyFormData);
    if (result.success) {
      toast.success(result.message);
      props.setToggle(false);
    } else {
      toast.error(result.message);
    }
    setloading(false);
  };

  return (
    <Box sx={{ width: "90%", margin: "auto", marginTop: 3 }}>
      <Loading isLoading={loading} />

      <UploadDocument Url={Url} setUrl={setUrl} />

      <Button
        title="Upload"
        variant="contained"
        sx={{ width: "90%", position: "absolute", bottom: 10, left: "5%" }}
        onClick={handleSubmit}
        className="w-full mt-5"
      />
    </Box>
  );
}

export default AddImages;
