import React from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import Button from "../../components/core/Button";
import UploadDocument from "../../components/core/UploadDocument";
import Dpicker from "../../components/core/Dpicker";
import Textarea from "../../components/core/Textarea";

function Report(props) {
  const { loading, formik } = props;
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = formik;

  return (
    <Container>
      <UploadDocument
        touched={touched.url}
        errors={errors.url}
        label="Upload Image"
        Url={values.url}
        setUrl={(e) => {
          setFieldValue("url", e);
        }}
      />
      <TextInput
        label="Report Name"
        placeholder="Enter report name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.name}
        errors={errors.name}
        name="name"
        id="name"
        Icon={null}
        multiline={true}
      />
      <Textarea
        label="Description"
        placeholder="Enter Description"
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.description}
        errors={errors.description}
        name="description"
        id="description"
        Icon={null}
        multiline={true}
      />

      <Dpicker
        label="Report Date"
        placeholder="Report Date"
        value={values.reportDate}
        onChange={(e) => {
          setFieldValue("reportDate", e);
        }}
        onBlur={handleBlur}
        touched={touched.reportDate}
        errors={errors.reportDate}
        name="reportDate"
        id="reportDate"
        maxLength={10}
        Icon={null}
      />

      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20, width: "100%" }}
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default Report;
