import * as yup from "yup";

const staffSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  role: yup.string().required("Role is required"),
  phone: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  pincode: yup.string().required("Pincode is required"),
  profilePic: yup.string().required("Profile Picture is required"),
  adhaarNumber: yup.string().required("Adhaar Number is required"),
});

export default staffSchema;
