import { Box } from "@mui/material";
import React from "react";
import Text from "../core/Text";

function TextWithDescription(props) {
  return (
    <Box className="my-3">
      <Text className="text-[14px] font-poppins ">{props.heading}</Text>

      <Text className="text-[13px] text-black font-poppins ">
        {props.value}
      </Text>
    </Box>
  );
}

export default TextWithDescription;
