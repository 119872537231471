import React, { useEffect, useState } from "react";
import Table from "../../../components/core/Table";
import { TableRow, Box } from "@mui/material";
import { postApi, putApi } from "../../../hooks/Api";
import { toast } from "react-toastify";
import TabCell from "../../../components/core/TabCell";
import formateDate from "../../../hooks/formateDate";
import Button from "../../../components/core/Button";

function Appointments() {
  const [state, setState] = useState([]);
  const [currentpage, setcurrentPage] = useState(1);
  const [nextPage, setnextPage] = useState(null);
  const [previousPage, setpreviousPage] = useState(null);
  const [totalPages, settotalPages] = useState(0);

  const getData = async () => {
    const response = await postApi("appointment/get", {
      page: currentpage,
      size: 15,
    });
    setState(response?.data?.data);
    setnextPage(response?.data?.nextPage);
    setpreviousPage(response?.data?.previousPage);
    settotalPages(response?.data?.totalPages);
  };
  const handlePageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  useEffect(() => {
    getData();
  }, [currentpage]);

  const tableHeaders = [
    { name: "Id", id: "Serial" },
    { name: "Name", id: "Contact" },
    { name: "Appointment", id: "appointment" },
    { name: "Registration Date", id: "registration" },
    { name: "Status", id: "status" },
    { name: "Action", id: "action" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>
          {row?.gender === "Male" ? "Mr. " : "Mrs "}
          {row?.patient?.firstname}
        </TabCell>
        <TabCell>
          {formateDate(row?.date)} {row?.time.toLocaleString()}
        </TabCell>
        <TabCell>{formateDate(row?.createdAt)}</TabCell>
        <TabCell>
          {row?.status[0].toUpperCase() + row?.status?.slice(1)}
        </TabCell>
        <TabCell>
          {row?.status === "pending" ? (
            <Button
              title="Approve"
              onClick={async () => {
                const response = await putApi(
                  `appointment/approved/${row?.appointmentId}`
                );
                if (response?.success) {
                  toast.success(response?.message);
                  fetch();
                } else {
                  toast.error(response?.message);
                }
              }}
            />
          ) : (
            "Appointment Approved"
          )}
        </TabCell>
      </TableRow>
    );
  };
  return (
    <Box>
      <Table
        extra={{
          rows: state ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
          handlePageChange: handlePageChange,
          nextPage: nextPage,
          previousPage: previousPage,
          totalPages: totalPages,
          currentpage: currentpage,
        }}
      />
    </Box>
  );
}

export default Appointments;
