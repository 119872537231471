import Login from "../pages/Login";
import Home from "../pages/Home";
import NotFoundPage from "../pages/NotFound";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Contact from "../pages/Contact";
import TermsCondition from "../pages/TermsCondition";
import RefundPolicy from "../pages/RefundPolicy";
import BookAppointment from "../pages/BookAppointment";
import AddPatient from "../Forms/Add/Patient";
import Success from "../pages/Success";
import { Navigate } from "react-router-dom";

export default function PublicRoute(isLoggedIn, role) {
  return [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: isLoggedIn ? <Navigate to="/dashboard" /> : <Login />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/terms-conditions",
      element: <TermsCondition />,
    },
    {
      path: "/refund-policy",
      element: <RefundPolicy />,
    },
    {
      path: "/book-appointment",
      element: <BookAppointment />,
    },
    {
      path: "/registration",
      element: <AddPatient />,
    },
    {
      path: "/success",
      element: <Success />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ];
}
