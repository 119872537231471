const Yup = require("yup");

const physicalStats = Yup.object().shape({
  weight: Yup.number().required("Weight is required"),
  // bmi: Yup.number().required("BMI is required"),
  // bodyFat: Yup.number().required("Body fat percentage is required"),
  // fat_freeWeight: Yup.number().required("Fat-free weight is required"),
  // subcutaneousFat: Yup.number().required(
  //   "Subcutaneous fat percentage is required"
  // ),
  // visceralFat: Yup.number().required("Visceral fat percentage is required"),
  // bodyWater: Yup.number().required("Body water percentage is required"),
  // skeletalMuscle: Yup.number().required(
  //   "Skeletal muscle percentage is required"
  // ),
  // muscleMass: Yup.number().required("Muscle mass is required"),
  // boneMass: Yup.number().required("Bone mass is required"),
  // protein: Yup.number().required("Protein is required"),
  // bmr: Yup.number().required("Basal metabolic rate is required"),
  // metabolicAge: Yup.number().required("Metabolic age is required"),
});

export default physicalStats;
