import React, { useEffect, useState } from "react";
import Container from "../../components/components/Container";
import { postApi } from "../../hooks/Api";
import { Box, TableRow } from "@mui/material";
import TabCell from "../../components/core/TabCell";
import Table from "../../components/core/Table";
import { DownloadingOutlinedIcon } from "../../Data/Icon";
import formateDate from "../../hooks/formateDate";
import Text from "../../components/core/Text";
import { Link } from "react-router-dom";

function Revenue() {
  const [state, setState] = useState([]);
  const [currentpage, setcurrentPage] = useState(1);
  const [nextPage, setnextPage] = useState(null);
  const [previousPage, setpreviousPage] = useState(null);
  const [totalPages, settotalPages] = useState(0);

  const getData = async () => {
    const response = await postApi("payment/get", {
      page: currentpage,
      size: 15,
    });
    setState(response?.data?.data);
    setnextPage(response?.data?.nextPage);
    setpreviousPage(response?.data?.previousPage);
    settotalPages(response?.data?.totalPages);
  };
  const handlePageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  useEffect(() => {
    getData();
  }, [currentpage]);

  const tableHeaders = [
    { name: "Serial No.", id: "Serial" },
    { name: "Payments Id", id: "PaymentsId" },
    { name: "Medicine Charge", id: "medicineCharge" },
    { name: "Consultation Fee", id: "consultationFee" },
    { name: "Amount", id: "amount" },
    { name: "Mode", id: "mode" },
    { name: "Created At", id: "createdAt" },
    { name: "Action", id: "action" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>{row?.paymentId}</TabCell>
        <TabCell>{row?.medicineCharge}</TabCell>
        <TabCell>{row?.consultationFee}</TabCell>
        <TabCell> ₹ {row?.amount}</TabCell>
        <TabCell>{row?.mode}</TabCell>
        <TabCell>{formateDate(row?.createdAt)}</TabCell>
        <TabCell>
          <Link
            sx={{ cursor: "pointer" }}
            to={`http://192.168.131.204:5000/api/payment/pdf/${row?.paymentId}`}
            target="_blank"
          >
            <DownloadingOutlinedIcon />
          </Link>
        </TabCell>
      </TableRow>
    );
  };
  return (
    <Container>
      <Box className="mt-4 bg-white rounded-t-sm px-2 flex flex-row justify-between items-center">
        <Box>
          <Text className="text-lg font-semibold  tracking-wide">Payments</Text>
          <Text className=" text-xs text-gray-500">
            Here you can see the list of Payment
          </Text>
        </Box>
      </Box>

      <Table
        extra={{
          rows: state ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
          handlePageChange: handlePageChange,
          nextPage: nextPage,
          previousPage: previousPage,
          totalPages: totalPages,
          currentpage: currentpage,
        }}
      />
    </Container>
  );
}

export default Revenue;
