// Import necessary Material-UI components
import React from 'react';
import { Typography, Button, Container, Box, } from '@mui/material';
import { Link } from 'react-router-dom'; // If using React Router, adjust the import accordingly
import SendIcon from '@mui/icons-material/Send';

const NotFoundPage = () => {


    return (
        <Container className='flex flex-row justify-center h-screen items-center' >
            <Box className="text-center">
                <Typography variant="h1" className='text-gray-500 font-medium'>
                    404 - Not Found
                </Typography>
                <Typography variant="body1" className='text-center my-4 ' >
                    The page you are looking for might be unavailable or does not exist.
                </Typography>
                <Button
                    className='text-white'
                    endIcon={<SendIcon />}
                    variant="contained"
                    color="primary"
                    component={Link} // If using React Router, adjust the component prop accordingly
                    to="/"

                >
                    Go Home
                </Button>
            </Box>
        </Container>
    );
};

export default NotFoundPage;
