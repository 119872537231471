import React, { useEffect, useState } from "react";
import Text from "../../../components/core/Text";
import { Box, Card, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import { getApi } from "../../../hooks/Api";
import formateDate from "../../../hooks/formateDate";
import TextWithDescription from "../../../components/components/TextWithDescription";
import TabPanel from "../../../components/components/TabPanel";
import Disease from "./Disease";
import PastDiseases from "./PastDisease";
import Report from "./Report";
import History from "./History";
import PhysicalStats from "./PhysicalStats";
import Appointments from "./Appointment";
import UserPayment from "./UserPayment";
import Personal from "./Personal";
import FormContainer from "../../../components/components/FormContainer";

function Patient() {
  const { id } = useParams();

  const [patient, setpatient] = useState({});
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetch = async () => {
    const response = await getApi(`patient/${id}`);
    setpatient(response.patient);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="md:px-8">
      <Card className="p-4 border border-slate-200">
        <Text className="text-lg  font-bold">
          Patient Information ({patient?.registrationNumber})
        </Text>

        <Divider orientation="horizontal" className="mt-4" />

        <Box className="flex flex-row flex-wrap md:flex-nowrap  my-4 space-x-3 items-center">
          <img
            src={patient.image}
            alt="patient"
            className="rounded w-60 h-60 object-contain"
          />
          <Grid container className="mt-5 md:px-10 px-0">
            <FormContainer>
              <TextWithDescription
                heading="Name"
                value={patient?.firstname + " " + patient.lastname}
              />
              <TextWithDescription
                heading="Date of Birth"
                value={formateDate(patient?.dateOfBirth)}
              />
              <TextWithDescription heading="Gender" value={patient?.gender} />
              <TextWithDescription
                heading="Blood Group"
                value={patient?.bloodGroup}
              />

              <TextWithDescription
                heading="Occupation"
                value={patient?.occupation}
              />
              <TextWithDescription
                heading="Phone Number"
                value={patient?.phone}
              />
              <TextWithDescription heading="Address" value={patient?.address} />

              <TextWithDescription heading="Email" value={patient?.email} />
              <TextWithDescription
                heading="Emergency Contact"
                value={patient?.secondaryNumber}
              />

              <TextWithDescription
                heading="Adhaar Number"
                value={patient?.adhaarNumber}
              />
              <TextWithDescription
                heading="Date of Registration"
                value={formateDate(patient?.dateOfRegistration)}
              />
              <TextWithDescription
                heading="Registration Number"
                value={patient?.registrationNumber}
              />
            </FormContainer>
          </Grid>
        </Box>

        <Divider orientation="horizontal" className="mt-4" />

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon tabs example"
          className="bg-gray-50"
          variant="scrollable"
        >
          <Tab label="Personal" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Diseases" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Prescription" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Reports" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Past Diseases" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Physical Stats" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Mental Stats" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Family History" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Payments" sx={{ fontSize: 13, fontWeight: 600 }} />
          <Tab label="Appointments" sx={{ fontSize: 13, fontWeight: 600 }} />
        </Tabs>

        <Divider orientation="horizontal" />

        <TabPanel value={value} index={0}>
          <Personal patient={patient} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Disease />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PastDiseases />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Report />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <History />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <PhysicalStats />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <UserPayment />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <Appointments />
        </TabPanel>
      </Card>
    </div>
  );
}

export default Patient;
