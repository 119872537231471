import React, { useState, useEffect } from "react";
import Table from "../../components/core/Table";
import formateDate from "../../hooks/formateDate";
import TableRow from "../../components/core/TableRow";
import { postApi } from "../../hooks/Api";
import TabCell from "../../components/core/TabCell";
import Container from "../../components/components/Container";

function Transaction() {
  const [state, setState] = useState([]);
  const [currentpage, setcurrentPage] = useState(1);
  const [nextPage, setnextPage] = useState(null);
  const [previousPage, setpreviousPage] = useState(null);
  const [totalPages, settotalPages] = useState(0);

  const getData = async () => {
    const response = await postApi("transaction/get", {
      page: currentpage,
      size: 15,
    });
    setState(response?.data?.data);
    setnextPage(response?.data?.nextPage);
    setpreviousPage(response?.data?.previousPage);
    settotalPages(response?.data?.totalPages);
  };
  const handlePageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  useEffect(() => {
    getData();
  }, [currentpage]);

  const tableHeaders = [
    { name: "TransactionId", id: "transactionId" },
    { name: "Patient Name", id: "name" },
    { name: "RefrenceId", id: "refrenceId" },
    { name: "Amount", id: "amount" },
    { name: "Status", id: "status" },
    { name: "Description", id: "description" },
    { name: "Created At", id: "CreatedAt" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow key={index}>
        <TabCell>{row?.transactionId}</TabCell>
        <TabCell>{row?.Patient?.firstName}</TabCell>
        <TabCell>{row?.refrenceId}</TabCell>
        <TabCell> ₹ {row?.amount}</TabCell>
        <TabCell>{row?.status}</TabCell>
        <TabCell> {row?.description}</TabCell>
        <TabCell>{formateDate(row.createdAt)}</TabCell>
      </TableRow>
    );
  };

  return (
    <Container>
      <Table
        extra={{
          rows: state ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
          handlePageChange: handlePageChange,
          nextPage: nextPage,
          previousPage: previousPage,
          totalPages: totalPages,
          currentpage: currentpage,
        }}
      />
    </Container>
  );
}

export default Transaction;
