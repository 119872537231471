import React, { useState, useEffect } from "react";

import StarIcon from "@mui/icons-material/Star";
import { getApi } from "../../hooks/Api";

function Review() {
  const [feedback, setfeedback] = useState([]);

  const fetch = async () => {
    const response = await getApi("feedback/get", {
      approved: true,
    });
    setfeedback(response.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  const reviews = [
    {
      patient: {
        firstname: "Sonu Vishwakarma",
      },
      rating: 5,
      comment:
        "I am from Indore and my family  shifted in bhopal from 12 months back.my wife is  facing depression, anxiety,anger,and disturbed Sleep, fear of Speaking in public ,mood swings and menstrual problems.last month .I got contact of Dr Anoop and I consulted him for wife  my problems. By taking 3 month of medicine, she feel very happy,got confidence and cycle became regular that is the big change I feel.He is very positive and calm doctor.He listens to us like family and guide  us.The Best doctor I ever met.",
      date: "2024-05-20",
    },
    {
      patient: {
        firstname: "kanchan shukla",
      },
      rating: 4,
      comment:
        "Good evening Sir and all I am very happy to took treatment from Dr Anoop Sir for Uterine submucosal fibroid and chocolate cyst with endometriosis for 6 months after taking  homeopathic treatment my pain during periods with heavy bleeding is almost stop .and energy level is high.Thank u sir",
      date: "2024-05-18",
    },
    {
      patient: {
        firstname: "Sadiq Ali",
      },
      rating: 5,
      comment:
        "I consulted dr Anoop sir for skin problems and relief within 30 days 60% problems very nice dr and very effective Medicine he is A good counselor best homeopathy doctor in bhopal A helpful doctor A good human really very good doctor in bhopal ❤❤.",
      date: "2024-05-15",
    },
    {
      patient: {
        firstname: "Ali Muttaqui",
      },
      rating: 3,
      comment:
        "Dr Anoop Chaudhary professional approach sympathetic and detailed analysis of patient  makes him one of the outstanding doctor.I am impressed by the way he makes notes on with the patient ailment and very professional approach in diagnosis. He is one of the rare homeopath who have a very good knowledge of human anatomy and physiology. Besides clinical examination he is also considering the pathological and other Investigation normally being taken up by Allopathic doctors.Very much satisfied by his treatment . He is one of the best in the city.",
      date: "2024-05-10",
    },
    {
      patient: {
        firstname: "Shruti Srivastava",
      },
      rating: 4,
      comment:
        "I have several good experiences with Dr Anoop. First time I met Dr. Anoop in 2013, when my daughter got skin infection after dysentry, no body was able to tell the actual problem, but Dr. Anoop  immediately understood the actual cause of skin problem and cured the problem. I also had anxiety and ibs problem, and it was cured by Dr,. Anoop only I am at much relief. Recently my daughter has some skin related issue and without any doubt I consulted Dr anoop again and my daughter got relief and i am very much hopeful for the full recovery. 🙏🙏🙏🙏",
      date: "2024-05-08",
    },
  ];

  return (
    <div className=" py-[6%] lg:py-[5%] container mx-auto px-6" id="review">
      <p className="text-center text-black font-bold  font-poppins lg:text-4xl text-2xl">
        What our Patient Say
      </p>
      <p className="text-center  font-normal  font-poppins text-base mt-5">
        People are happy with our treatment and they are sharing their
        experience with us.
      </p>
      <div className="grid lg:grid-cols-3 gap-8 my-[20%] lg:my-[5%]">
        {feedback.concat(reviews).map((data, index) => {
          return (
            <div
              key={index}
              className="w-full h-full bg-white items-start justify-start flex flex-col rounded-lg p-5 shadow-md"
            >
              <div className="flex flex-row justify-start items-center space-x-5">
                {data?.patient?.image && (
                  <img
                    src={data?.patient?.image}
                    alt=""
                    className="w-20 h-20 object-cover rounded-full border"
                  />
                )}

                <div>
                  <p className="font-poppins text-lg md:text-xl  lg:text-2xl  font-medium text-black">
                    {data?.patient?.firstname}
                  </p>
                  {new Array(data.rating).fill(0).map((_, index) => {
                    return (
                      <StarIcon
                        key={index}
                        className="text-yellow-500"
                        fontSize="small"
                      />
                    );
                  })}
                </div>
              </div>
              <p className="text-base my-3 font-poppins text-gray">
                {data?.comment?.slice(0, 200)}.....
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Review;
